import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  redirect: {
    name: 'CCTV'
  }
},
{
  path: '/auth',
  name: 'Auth',
  component: () => import('../views/Auth.vue'),
  meta: {
    guest: true,
    layout: 'no-navbar'
  }
},
{
  path: '/detections/:cameraId?',
  name: 'Detections',
  component: () => import('../views/Detections.vue'),
  meta: {
    requiresAuth: true,
    layout: 'no-navbar'
  }
},
{
  path: '/cameras',
  name: 'CCTV',
  component: () => import('../views/Cameras.vue'),
  meta: {
    requiresAuth: true
  }
},
{
  path: '/labs',
  name: 'Lab',
  component: () => import('../views/Cameras.vue'),
  meta: {
    requiresAuth: true
  }
},
// {
//   path: '/drone',
//   name: 'Drone',
//   component: () => import('../views/Drone.vue'),
//   meta: {
//     requiresAuth: true
//   }
// },
{
  path: '/aisc',
  name: 'AISC',
  component: () => import('../views/Cameras.vue'),
  meta: {
    requiresAuth: true
  }
},
{
  path: '/user-management',
  name: 'User Management',
  component: () => import('../views/UserManagement.vue'),
  meta: {
    requiresAuth: true,
    isAdmin: true
  }
}]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  let user = JSON.parse(localStorage.getItem('user'))

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token == null) {
      next({
        name: 'Auth',
        query: {
          nextUrl: to.fullPath
        }
      })
    } else {
      if (to.matched.some(record => record.meta.isAdmin)) {
        if (user.role == 'admin') {
          next()
        } else {
          next({
            name: 'Home'
          })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (token == null) {
      next()
    } else {
      next({
        name: 'Home'
      })
    }
  } else {
    next()
  }
})

export default router