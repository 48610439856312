import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSocketIO from 'vue-socket.io'
import 'vue-select/dist/vue-select.css';
import 'bootstrap'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/css/style.scss'

import VCalendar from "v-calendar";
import vSelect from 'vue-select'

import Default from './layouts/Default'
import NoNavbar from './layouts/NoNavbar'

let socketUrl = `${process.env.VUE_APP_API_CLOUD}`.replace("/api", "");

Vue.use(new VueSocketIO({
  debug: false,
  connection: socketUrl,
}))

Vue.component('v-select', vSelect)
Vue.use(VCalendar, {});

Vue.component('default-layout', Default)
Vue.component('no-navbar-layout', NoNavbar)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
