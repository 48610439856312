<template>
  <div id="app">
    <component :is="layout" @toggleSound="toggleSound">
      <router-view
        :latestDetection="latestDetection"
        :latestViolation="latestViolation"
      />
    </component>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Sound from "@/assets/sounds/detection.mp3";

const defaultLayout = "default";

export default {
  data() {
    return {
      latestDetection: null,
      latestViolation: null,
      notificationSound: new Audio(Sound),
      muteSound: true,
    };
  },
  computed: {
    layout() {
      let layout = `${this.$route.meta.layout || defaultLayout}-layout`;

      return layout;
    },
  },
  methods: {
    toggleSound(data) {
      this.muteSound = data;
    },
    triggerToast(type, data) {
      if (type && data) {
        Swal.fire({
          toast: true,
          position: "top",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "X",
          buttonsStyling: false,
          customClass: {
            cancelButton: "btn btn-link btn-sm",
            container: "toast-offset",
            title: "text-left",
          },
          timer: 10000,
          timerProgressBar: true,
          icon: type == "aisc" ? "warning" : "success",
          title:
            type == "aisc"
              ? "AISC violation detected!"
              : `New detection at ${data.camera.name}`,
          html:
            type == "detection"
              ? `<a class='btn btn-dark btn-sm' href='./#/detections/${data.camera.id}'>View</a>`
              : "",
        });
      }
    },
  },
  sockets: {
    connect: function () {
      console.log("Socket connected");
    },
    disconnect() {
      console.warn("Socket disconnected");
    },
    personDetected: function (data) {
      this.latestDetection = data;

      if (!this.muteSound) {
        this.notificationSound.play();
      }

      this.triggerToast("detection", data);
    },
    violation: function (data) {
      this.latestDetection = data;

      if (!this.muteSound) {
        this.notificationSound.play();
      }

      this.triggerToast(
        data.camera.type == "aisc" ? "aisc" : "detection",
        data
      );
    },
  },
};
</script>